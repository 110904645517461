import { Button, makeStyles, Theme } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import React from 'react';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

import { useNavigate } from '../../utilities/navigate';
import { Routes } from '../../infrastructure/routes';

const useStyles = makeStyles<Theme>((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  button: {
    color: theme.palette.common.white,
    width: '100%',
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 185,
    },
  },
  seeBelowButton: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: theme.spacing(-1),
    },
  },
  space:{
    marginLeft:'10px'
  },
}));

type LandingPageButtonsProps = {
  onClickBelow: () => void;
  onClick: () => void;
};

export const LandingPageButtons = ({ onClickBelow, onClick }: LandingPageButtonsProps) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  if (keycloak.authenticated) {
    navigate(Routes.CR_LOGIN);
  }

  return (
    <div className={clsx( classes.space , classes.seeBelowButton)}>
      {!keycloak.authenticated && (
        <Button
          variant="contained"
          color="secondary"
          className={clsx(classes.button, classes.seeBelowButton)}
          onClick={() => keycloak.login()}
          size="large"
        >
          {translate('login')}
        </Button>
      )}
      {!!keycloak.authenticated && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => keycloak.logout()}
          size="large"
        >
          {translate('logout')}
        </Button>
      )}
    </div>
  );
};
