import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import NavigatePreviousIcon from '@material-ui/icons/NavigateBefore';
import { useParams, Link } from 'react-router-dom';

import { LoadingWrapper } from 'ui/LoadingWrapper';
import { Layout } from 'ui/Layout';
import { useGetQuestionnaireByIdQuery } from 'domain/admin/api/admin.api';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import {
  useGetAssessmentByIdQuery,
  useGetSectionsByAssessmentIdQuery,
} from 'domain/company-representative/api/companyRepresentative.api';
import {
  CompanyRepresentativeRoutes,
  getCompanyRepresentativePath,
} from 'domain/company-representative/CompanyRepresentativeRoutes';
import { CompanyRepresentativeSAResultHeader } from 'domain/company-representative/company-representative-sa-result/CompanyRepresentativeSAResultHeader';
import { AdminSAResultHeader } from 'domain/admin/admin-establishments/admin-establishments-details/AdminSAResultHeader';
import { CompanyRepresentativeSAResult } from 'domain/company-representative/company-representative-sa-result/CompanyRepresentativeSAResult';
import { isAdmin as isAdminCheck } from 'infrastructure/services/auth.utils';
import { useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { selectRole } from 'infrastructure/services/authSlice.selector';
import { getAdminPath } from 'domain/admin/AdminRoutes';

import { Routes } from '../domain/common/Routes';

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const SAResultContainer: React.FC = () => {
  const classes = useStyles();
  const { id, establishmentId } = useParams<{ id: string; establishmentId?: string }>();
  const assessmentId = +id;
  const { data: assessment, isLoading, isFetching } = useGetAssessmentByIdQuery(assessmentId);
  const { data: questionnaire } = useGetQuestionnaireByIdQuery(assessment?.questionnaireId, {
    skip: !assessment?.questionnaireId,
  });
  const {
    data: { results: sections } = {
      count: 0,
      results: [],
    },
  } = useGetSectionsByAssessmentIdQuery({
    assessmentId,
  });

  const isAdminLogged = isAdminCheck(useAppSelector(selectRole));

  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const adminTranslate = useCreateTranslate(TranslationNamespace.admin);

  return (
    <Layout maxWidth="lg">
      <LoadingWrapper isLoading={isLoading || isFetching}>
        {assessment && (
          <div className={classes.main}>
            <Breadcrumbs separator={<NavigatePreviousIcon fontSize="small" />} aria-label="breadcrumb">
              <MuiLink
                component={Link}
                color="textSecondary"
                to={
                  isAdminLogged
                    ? `${getAdminPath(Routes.establishment)}/${establishmentId}`
                    : getCompanyRepresentativePath(CompanyRepresentativeRoutes.companySelect)
                }
              >
                {isAdminLogged ? adminTranslate('establishmentDetails') : translate('companySelection')}
              </MuiLink>
              <Typography color="textPrimary">{translate('detailsOfSA')}</Typography>
            </Breadcrumbs>
            {establishmentId ? <AdminSAResultHeader id={establishmentId} /> : <CompanyRepresentativeSAResultHeader />}
            <CompanyRepresentativeSAResult assessment={assessment} questionnaire={questionnaire} sections={sections} />
          </div>
        )}
      </LoadingWrapper>
    </Layout>
  );
};
