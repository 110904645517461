import { TranslateFn } from 'utilities/translate.hook';

import { SelfAssessmentPassingStatus } from '../../../types/Assessment';

export const getAttemptsMessageFn =
  (translate: TranslateFn) =>
  (passingStatus: SelfAssessmentPassingStatus | null, attemptsNumber: number = 10) => {
    switch (passingStatus) {
      case SelfAssessmentPassingStatus.BELOW_PASSING_SCORE:
        if (attemptsNumber > 0) {
          return translate('attemptsFailInfo', {
            attemptsInfo: `${attemptsNumber}`,
          });
        }
        return translate('retryBlocked', {
          attemptsInfo: `${attemptsNumber}`,
        });
      case SelfAssessmentPassingStatus.ABOVE_HIGH_SCORE_VALUE:
      case SelfAssessmentPassingStatus.BELOW_HIGH_SCORE_VALUE:
        return translate('congratulations');
      default:
        throw Error('Unsupported passing SA status');
    }
  };
