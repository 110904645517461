import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { EstablishmentInfo } from 'types/Establishment';

import { getAttemptsMessageFn } from '../utils/getAttemptsMessageFn';
import { useGetAssessmentByIdQuery } from '../api/companyRepresentative.api';
import { TypeOfPlatform } from '../../../infrastructure/services/auth.type';

type PropTypes = {
  establishment: EstablishmentInfo;
  label: string;
  onClick: () => void;
  platform: TypeOfPlatform.OSH;
};

const useStyles = makeStyles((theme) => ({
  button: {
    flexShrink: 0,
    height: '36px',
    width: '112px',
    fontWeight: 'bold',
  },

  firstButton: {
    flip: false,
    marginRight: theme.spacing(2),
  },
}));

export const CompanyRepresentativeTooltipRetryButton: React.FC<PropTypes> = ({ establishment, label,
 onClick, platform }) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const classes = useStyles();
  const [passingStatus, setPassingStatus] = useState<number>(0);
  const [attemptsLeftCA, setAttemptsLeftCA] = useState<number>(0);
  const [attemptsLeftOSH, setAttemptsLeftOSH] = useState<number>(0);
  const getAttemptsMessage = getAttemptsMessageFn(translate);
  const { assessmentAttemptsLeftOSH } = establishment;
  const { assessmentAttemptsLeftCA } = establishment;
  const { data: assessment } = useGetAssessmentByIdQuery(establishment.assessmentCurrentObjIdOSH || 0);

  useEffect(() => {
    assessment?.passingStatus && setPassingStatus(assessment.passingStatus);
    platform === TypeOfPlatform.OSH ? setAttemptsLeftOSH(assessmentAttemptsLeftOSH) :
    setAttemptsLeftCA(assessmentAttemptsLeftCA)
  }, [setPassingStatus,  attemptsLeftCA, attemptsLeftOSH, assessment, platform,
  assessmentAttemptsLeftCA,assessmentAttemptsLeftOSH]);

  return (
    <Tooltip title={getAttemptsMessage(passingStatus, attemptsLeftCA)} arrow>
      <span>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          className={`${classes.firstButton} ${classes.button}`}
          onClick={onClick}
          disabled={attemptsLeftCA === 0}
        >
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};
