import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { TranslationNamespace } from 'i18n/config';
import { Button } from 'ui/Button';
import { Dialog } from 'ui/Dialog';
import { useCreateTranslate } from 'utilities/translate.hook';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';

import { useDeleteQuestionnaireMutation } from '../api/admin.api';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    '& button:first-child': {
      flip: false,
      marginRight: theme.spacing(2),
    },
  },
  button: {
    flex: 1,
  },
  header: {
    textAlign: 'center',
  },
}));

type DeleteDialogProps = {
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement;
};

export const DeleteDialog = ({ open, handleClose, anchorEl }: DeleteDialogProps) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const showNotification = useNotifications();
  const [delateQuestionnaire, { isLoading: isDeleteLoading }] = useDeleteQuestionnaireMutation();

  const handleDeleteQuestionnaire = () => {
    if (anchorEl) {
      const questionnaireId = anchorEl.getAttribute('data-id');
      if (questionnaireId && !isDeleteLoading) {
        delateQuestionnaire(+questionnaireId)
          .unwrap()
          .catch((err: BaseQueryError) => {
            showNotification(NotificationType.Error, { errorId: err.data.errorCode });
          });
        handleClose();
      }
    }
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Typography variant="h6" className={classes.header}>
        {translate('delete')}
      </Typography>

      <Typography className={classes.subtitle} variant="body1">
        {translate('deleteDraft')}
      </Typography>

      <div className={classes.buttons} dir="ltr">
        <Button
          onClick={handleDeleteQuestionnaire}
          variant="contained"
          size="large"
          color="secondary"
          className={classes.button}
        >
          {translate('delete')}
        </Button>
        <Button variant="outlined" size="large" color="secondary" className={classes.button} onClick={handleClose}>
          {translate('close')}
        </Button>
      </div>
    </Dialog>
  );
};
