import React from 'react';
import { FormControl, makeStyles, TextField, Typography } from '@material-ui/core';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';
import i18n from 'i18next';

import { Role, RoleList } from 'infrastructure/services/auth.type';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { emailRegex, saudiPhoneNumberRegex } from 'infrastructure/constants/regex.constants';
import { useFormStyles } from 'styles/form';
import { preventDefaultIfNotNumberAndPlusValues } from 'utilities/preventDefaultIfNotNumberAndPlusValues';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';

import { useCreateUserMutation } from '../api/admin.api';
import { UsersDialog } from './UsersDialog';
import { useGetMeQuery } from '../../../infrastructure/services/user.api';
import { getReverseDirection } from '../../../utilities/useLanguage';

type UsersCreateDialogProps = {
  isFormOpen: boolean;
  handleClose: () => void;
};

type CreateUserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  accountType: Role;
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  field: {
    marginTop: theme.spacing(4),
  },
  select: {
    marginTop: theme.spacing(4),
    textAlign: 'left',
  },
  buttons: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  submitButton: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },

}));

const maxLength = 300;
const minLength = 1;

export const UsersCreateDialog: React.FC<UsersCreateDialogProps> = ({ isFormOpen, handleClose }) => {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const {
    handleSubmit,
    control,
    reset: resetForm,
  } = useForm<CreateUserInfo>({
    mode: 'onTouched',
  });
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);

  const [createUser, { isLoading }] = useCreateUserMutation();
  const { data: currentUser } = useGetMeQuery();
  const reverseDirection = getReverseDirection(i18n.language);

  const showNotification = useNotifications();

  const handleFormClose = () => {
    resetForm();
    handleClose();
  };

  const onUserSubmit: SubmitHandler<CreateUserInfo> = async ({
    email,
    firstName,
    lastName,
    phoneNumber,
    accountType,
  }) => {
    if (!isLoading) {
      await createUser({
        email,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        account_type: accountType,
      })
        .unwrap()
        .then(() => {
          handleFormClose();
          showNotification(NotificationType.Success, {
            message: translate('createdUserSuccess'),
          });
        })
        .catch((error: BaseQueryError) => {
          showNotification(NotificationType.Error, { errorId: error.data.errorCode });
        });
    }
  };

  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateCommon('fieldRequired');
      }

      return error.message;
    }

    return ' ';
  };

  const RulesName = {
    required: true,
      pattern: {
        value: /^[\sa-zA-Z\u0621-\u064A]+$/,
        message: translate('invalidTextFormat'),
      },
      maxLength: {
        value: maxLength,
        message: translateCommon('maxLength', { value: `${maxLength}` }),
      },
      minLength: {
        value: minLength,
        message: translateCommon('minLength', { value: `${minLength}` }),
      }
  }



  return (
    <UsersDialog isFormOpen={isFormOpen} handleClose={handleFormClose}>
      <form onSubmit={handleSubmit(onUserSubmit)}>
        <Typography variant="h6">{translate('createUser')}</Typography>
        <Typography variant="body2" className={classes.description}></Typography>
        <FormControl className={classes.formWrapper}>
          <Controller
            control={control}
            name="firstName"
            rules={ RulesName }
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('firstName')}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            rules={ RulesName }
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('lastName')}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
              pattern: {
                value: emailRegex,
                message: translate('invalidEmailAddressFormat'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('emailAddress')}
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: true,
              pattern: {
                value: saudiPhoneNumberRegex,
                message: translate('invalidPhoneFormat'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                type="text"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translateCommon('phoneNumber')}
                placeholder={translate('formatPhoneField')}
                inputMode="numeric"
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />
          <Controller
            name="accountType"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                select
                dir={reverseDirection}
                type="text"
                error={invalid}
                className={classes.select}
                variant="outlined"
                label={translateCommon('usertypeselect')}
              >
                {currentUser &&
                  RoleList(currentUser.currentplatform).map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {translate(role.name)}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        </FormControl>
        <div dir="ltr" className={classes.buttons}>
          <Button
            variant="outlined"
            size="medium"
            disabled={isLoading}
            color="secondary"
            className={classes.submitButton}
            onClick={handleFormClose}
            type="submit"
          >
            {translateCommon('cancel')}
          </Button>
          <Button
            variant="contained"
            size="medium"
            disabled={isLoading}
            color="secondary"
            className={classes.submitButton}
            type="submit"
          >
            {translate('createAccount')}
          </Button>
        </div>
      </form>
    </UsersDialog>
  );
};
