export type TokenData = {
  token: string;
  expiry: number;
};

export type TokenResponse = {
  refresh: string;
  access: string;
  account_type: Role;
  show_platform: boolean;
};

export type RefreshTokenResponse = Pick<TokenResponse, 'access' | 'account_type'>;

export interface AdminLoginCredentials {
  email: string;
  password: string;
  token: string;
}

export interface ConfirmationInvitationCredentials {
  form_id: string;
  password: string;
  repassword: string;
}

export interface AdminAuthenticateCredentials {
  email: string;
  password: string;
  google_authenticator_token: string;
}

export enum Role {
  Admin = 1,
  CompanyRepresentative = 2,
  Auditor = 3,
  Inspector = 4,
}

export type RoleDis = {
  value: Role;
  name: string;
};

export enum TypeOfPlatform {
  OSH = 1,
  CA = 2,
}

export const RoleList = (platform: TypeOfPlatform) => [
  { value: Role.Admin, name: 'adminRole' },
  { value: Role.Auditor, name: platform === TypeOfPlatform.OSH ? 'auditorRole' : 'auditorCARole' },
  { value: Role.Inspector, name: platform === TypeOfPlatform.OSH ? 'inspectorRole' : 'inspectorCARole' },
];

export enum TypeOfRequest {
  TechnicalSupport = 758,
  Inquire = 757,
}

export type TypeOfRequestDis = {
  value: TypeOfRequest;
  name: string;
};

export const TypeOfRequestList: TypeOfRequestDis[] = [
  { value: TypeOfRequest.TechnicalSupport, name: 'TechnicalSupport' },
  { value: TypeOfRequest.Inquire, name: 'Inquire' },
];

export type UserData = {
  tokenData: TokenData;
  role: Role;
  showPlatform: boolean;
};

export interface UserTowFactorResponse {
  email: string;
  first_login: boolean;
  google_authenticator_qr_image: string;
  google_authenticator_token: string;
}

export interface UserTowFactorInfo {
  email: string;
  firstLogin: boolean;
  googleAuthenticatorQRImage: string;
  googleAuthenticatorToken: string;
}

export interface InvitationConfirmationResponse {
  link: string;
  confirmed: boolean;
}

export interface CheckInvitationResponse {
  validate_success: boolean;
  confirmed: boolean;
}
