import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AdminRouter } from 'domain/admin/AdminRouter';
import { ADMIN_ROUTE } from 'domain/admin/AdminRoutes';
import { InspectorRouter } from 'domain/inspector/InspectorRouter';
import { INSPECTOR_ROUTE } from 'domain/inspector/InspectorRoutes';
import { AuditorRouter } from 'domain/auditor/AuditorRouter';
import { AUDITOR_ROUTE } from 'domain/auditor/AuditorRoutes';
import rootStore from 'infrastructure/store/rootStore';
import { CR_ROUTE } from 'domain/company-representative/CompanyRepresentativeRoutes';
import { CompanyRepresentativeRouter } from 'domain/company-representative/CompanyRepresentativeRouter';
import { CompanyRepresentativeRoute } from 'ui/PrivateRoute';
import { LandingPage } from 'domain/landing-page/LandingPage';
import { TermsAndConditions } from 'domain/landing-page/TermsAndConditions';

import { CompanyLogin } from './pages/CompanyRepresentativeLogin';
import { Routes } from '../infrastructure/routes';
import { NotificationsProvider } from '../infrastructure/notifications/NotificationsProvider';
import { theme } from './App.theme';
import { RTL } from './App.rtl';
import { FillMissingInfo } from './pages/FillMissingInfo';
import { ConfirmationPage } from '../domain/confirmation-invitation/ConfirmationPage';
import { NotFoundPage } from '../domain/common/page/NotFoundPage';
import CookiePolicyMessage from 'ui/CookiePolicyMessage';

const queryClient = new QueryClient();

function App() {
  const { i18n } = useTranslation();
  const direction = i18n.language === 'en' ? 'ltr' : 'rtl';
  document.body.dir = direction;

  return (
    <Provider store={rootStore}>
      <RTL>
        <ThemeProvider theme={i18n.language === 'ar' ? { ...theme, direction: 'rtl' } : { ...theme, direction: 'ltr' }}>
          <NotificationsProvider>
            <QueryClientProvider client={queryClient}>
              <CssBaseline />
              <Router>
                <Switch>
                  <Route path={Routes.TERMS_AND_CONDITIONS}>
                    <TermsAndConditions />
                  </Route>
                  <Route exact path={`${Routes.CONFIRMATION_INVITATION}/:formId`}>
                    <ConfirmationPage />
                  </Route>
                  <Route path={Routes.NOT_FOUND}>
                    <NotFoundPage />
                  </Route>
                  <CompanyRepresentativeRoute path={Routes.CR_START_SA}>

                  </CompanyRepresentativeRoute>
                    <CompanyRepresentativeRoute path={Routes.CA_START_SA}>

                  </CompanyRepresentativeRoute>
                  <CompanyRepresentativeRoute path={`${Routes.CR_MISSING_INFO}/:extId`}>
                    <FillMissingInfo />
                  </CompanyRepresentativeRoute>

                  <Route path={Routes.CR_LOGIN}>
                    <CompanyLogin />
                  </Route>
                  <Route path={ADMIN_ROUTE}>
                    <AdminRouter />
                  </Route>
                  <Route path={INSPECTOR_ROUTE}>
                    <InspectorRouter />
                  </Route>
                  <Route path={AUDITOR_ROUTE}>
                    <AuditorRouter />
                  </Route>
                  <Route path={CR_ROUTE}>
                    <CompanyRepresentativeRouter />
                  </Route>
                  <Route path={Routes.HOME}>
                    <LandingPage />
                  </Route>
                </Switch>
              </Router>
              <CookiePolicyMessage/>
            </QueryClientProvider>
          </NotificationsProvider>
        </ThemeProvider>
      </RTL>
    </Provider>
  );
}

export default App;
