import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';

import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { DuplicateIcon, TrashIcon, ViewDetailsIcon, EditIcon } from 'ui/icons/icons';
import { CustomMenuItem } from 'ui/CustomMenuItem';

import {  useDuplicateQuestionnaireMutation } from '../api/admin.api';
import { AssessmentTemplateStatus } from '../types/AssessmentTemplateStatus';

type PropsTypes = {
  anchorEl: HTMLElement;
  questionnaireStatus: AssessmentTemplateStatus;
  handleCloseMenu: () => void;
  handleEditQuestionnaire: () => void;
  handleDeleteQuestionnaire: () => void;
};

const useStyle = makeStyles((theme) => ({
  list: {
    marginLeft: 6,
  },
}));

export const TemplateTableMenuDialog: React.FC<PropsTypes> = ({
  anchorEl,
  questionnaireStatus,
  handleCloseMenu,
  handleEditQuestionnaire,
  handleDeleteQuestionnaire,
}) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyle();
  const [duplicateQuestionnaire, { isLoading: isDuplicateLoading }] = useDuplicateQuestionnaireMutation();
  const showNotification = useNotifications();

  const handleDuplicateQuestionnaire = () => {
    if (anchorEl) {
      const questionnaireId = anchorEl.getAttribute('data-id');
      if (questionnaireId && (!isDuplicateLoading)) {
        duplicateQuestionnaire(+questionnaireId)
          .unwrap()
          .catch((err: BaseQueryError) => {
            showNotification(NotificationType.Error, { errorId: err.data.errorCode });
          });
      }
    }
  };

  return (
    <Menu
      data-testid="template-table-menu-dialog"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <CustomMenuItem onClick={handleEditQuestionnaire}>
        <SvgIcon component={questionnaireStatus === AssessmentTemplateStatus.Draft ? EditIcon : ViewDetailsIcon} />
        <ListItemText className={classes.list} data-testid="template-dialog-edit">
          {translate(questionnaireStatus === AssessmentTemplateStatus.Draft ? 'editTemplate' : 'viewDetails')}
        </ListItemText>
      </CustomMenuItem>
      {questionnaireStatus === AssessmentTemplateStatus.Draft && (
        <CustomMenuItem onClick={handleDeleteQuestionnaire}>
          <SvgIcon component={TrashIcon} />
          <ListItemText className={classes.list} data-testid="template-dialog-delete">
            {translate('delete')}
          </ListItemText>
        </CustomMenuItem>
      )}
      <CustomMenuItem onClick={handleDuplicateQuestionnaire}>
        <SvgIcon component={DuplicateIcon} />
        <ListItemText className={classes.list} data-testid="template-dialog-duplicate">
          {translate('duplicate')}
        </ListItemText>
      </CustomMenuItem>
    </Menu>
  );
};
