import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { useNavigate } from 'utilities/navigate';
import { BackButton } from 'ui/buttons/BackButton';
import { selectEstablishmentInfo } from 'domain/company-representative/company-representative-login/selectors/establishmentInfo.selector';
import rootStore from 'infrastructure/store/rootStore';
import { isRefresh } from 'utilities/useLanguage';

import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';
import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
  },

  backButton: {
    alignSelf: 'flex-end',
  },

  texts: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  description: {
    marginTop: '12px',
  },
}));

export const CompanyRepresentativeSAResultHeader: React.FC = () => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const navigate = useNavigate();
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);
  const establishmentId = selectEstablishmentInfo(rootStore.getState())?.establishmentId || '';




  return (
    <div dir={reverseDirection} className={classes.main}>
      <BackButton
        className={classes.backButton}
        onClick={() => {
          navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.osh)}/${establishmentId}`);
          isRefresh();
        }}
      />
      <div dir={direction} className={classes.texts}>
        <Typography variant="h4">{translate('detailsOfSA')}</Typography>
        <Typography variant="body2" className={classes.description}>
          {translate('detailsDescription')}
        </Typography>
      </div>
    </div>
  );
};
