import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { useNavigate } from 'utilities/navigate';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { Dialog } from 'ui/Dialog';
import { EstablishmentInfo } from 'types/Establishment';
import { TypeOfPlatform } from 'infrastructure/services/auth.type';

import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';
import { useRedoAssessmentMutation } from '../api/companyRepresentative.api';

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  establishmentInfo: EstablishmentInfo;
  platform: TypeOfPlatform.OSH;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: 'center',
  },

  description: {
    marginTop: theme.spacing(1),
    minHeight: '40px',
  },

  buttons: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  submitButton: {
    flex: 1,
  },

  cancelButton: {
    flex: 1,
    flip: false,
    marginLeft: theme.spacing(2),
  },
}));

export const CompanyRepresentativeSARetryDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  establishmentInfo,
  platform,
}) => {
  const classes = useStyles();
  const translateSA = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const navigate = useNavigate();
  const showNotification = useNotifications();

  const [redoSelfAssessment, { isLoading }] = useRedoAssessmentMutation();

  const handleContinue = async () => {
    if (
      !isLoading && platform === TypeOfPlatform.OSH
        ? establishmentInfo.assessmentCurrentObjIdOSH
        : establishmentInfo.assessmentCurrentObjIdCA
    ) {
      await redoSelfAssessment(
        platform === TypeOfPlatform.OSH
          ? establishmentInfo.assessmentCurrentObjIdOSH
          : establishmentInfo.assessmentCurrentObjIdCA
      )
        .unwrap()
        .then(({ id }) => {
          navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${id}`);
        })
        .catch((err: BaseQueryError) => {
          showNotification(NotificationType.Error, { errorId: err.data.errorCode });
        });
    }
  };

  return (
    <Dialog open={open} handleClose={handleClose} className={classes.dialog}>
      <Typography variant="h6">{translateSA('areYouSureRetry')}</Typography>
      <Typography variant="body2" className={classes.description} color="secondary">
        {translateSA('attemptsToFillInfo', {
          attemptsInfo:
            platform === TypeOfPlatform.OSH
              ? `${establishmentInfo.assessmentAttemptsLeftOSH}`
              : `${establishmentInfo.assessmentAttemptsLeftCA}`,
        })}
      </Typography>
      <div dir="ltr" className={classes.buttons}>
        <Button
          data-testid="retry-cr-assessment-submit"
          variant="contained"
          size="medium"
          color="secondary"
          className={classes.submitButton}
          onClick={handleContinue}
        >
          {translateSA('yesContinue')}
        </Button>
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          className={classes.cancelButton}
          onClick={handleClose}
        >
          {translateCommon('cancel')}
        </Button>
      </div>
    </Dialog>
  );
};
