import React from 'react';
import { Link, makeStyles, Theme, Typography, useMediaQuery, useTheme, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

import { TranslationNamespace } from 'i18n/config';
import LogoMono from 'images/logo-mono.svg';
import LogoColor from 'images/logo-color.svg';
import { useCreateTranslate } from 'utilities/translate.hook';

import OshStripes from '../../images/osh-stripes.svg';



const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(10),
    padding: `0 ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    justifyContent: 'flex-end',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(4, 2),
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(4, 13),
    },
  },
  links: {
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  logo: {
    height: 40,
    [theme.breakpoints.up('md')]: {
      height: 48,
    },
  },
  title: {
    padding: '2%',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  li: {
    padding: theme.spacing(2),
  },
  list: {
    maxWidth: '1000px',
  },
  addPadding: {
    padding: '0 10%',
  },
  addbottom: {
    display: 'block',
    padding: '1% 0 3%',
    textAlign: 'justify',
    lineHeight: '2',
    fontSize: '1.2em',
  },
  backButton: {
    margin: theme.spacing(1),
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

export const TermsAndConditions: React.FC = () => {
  const theme = useTheme();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);
  const classes = useStyles();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const history = useHistory();
  const handleBackToHome = () => {
    history.replace('/');
  };
  return (
    <div>
      <header className={classes.header} dir="ltr">
        <Container maxWidth="lg" className={classes.main}>
          <div className={classes.imageWrapper}>
            <img alt="osh" src={OshStripes} />
            <img src={LogoColor} alt="Logo-color" className={classes.logo} />
          </div>
        </Container>
      </header>

      <div className={classes.addPadding} style={{ backgroundColor: 'white' }}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant={isUpMd ? 'h4' : 'h6'}>
            الشروط والأحكام
          </Typography>
          <Link
            className={classes.backButton}
            href="/"
            underline="none"
          >
            <Button className={classes.button} onClick={handleBackToHome}>
              العودة إلى الصفحة الرئيسية
            </Button>
          </Link>
        </div>

        <span className={classes.addbottom}>
          تمثل هذه الوثيقة الحد الأدنى من الشروط والأحكام التي تنظم العلاقة بين منصة "برنامج السلامة والصحة المهنية"
          والمستفيد والتعريف بالخدمات المقدمة والتي يتوجب على المستخدم الموافقة عليها والقبول بمضمونها، بالإضافة إلى
          الأحكام الختامية وحل الخلافات.
        </span>
        <Typography variant="h6">أولاً: التعريفات:</Typography>
        <span className={classes.addbottom}>
          تحمل الكلمات الآتية أينما وردت المعاني المبينة أمامها مالم يقتضي سياق النص غير ذلك.
          <ul>
            <li><b>الوزارة:</b> وزارة الموارد البشرية والتنمية الاجتماعية.</li>
            <li><b>المنصة أو البوابة:</b> الموقع الالكتروني لبرنامج السلامة والصحة المهنية والمعنون بالنطاق الالكتروني <a href="https://www.sosh.sa">www.sosh.sa</a></li>
            <li><b>المطور أو مزود الخدمة:</b> شركة تكامل لخدمات الأعمال.</li>
            <li><b>المستخدم:</b> الشخص المفوض عن المنشآت لمباشرة إجراءات الدخول والاستفادة من خدمات المنصة.</li>
            <li><b>المستفيد من الخدمة:</b> هي المنشآت التي تستهدف تطوير بيئة عملها لتكون بيئة عمل آمنة.</li>
            <li><b>التدريب:</b> برامج تدريبية تقدمها المنصة للمستخدمين أو المنشآت الصغيرة للتوعية
             بأهمية تقييم المنشأة وكيفية التقييم بالإضافة إلى التعريف بالمعايير الخاصة بالبرنامج.</li>
            <li><b>المنشأة:</b> أي مؤسسة فردية أو منشأة تجارية ترتبط بالمنصة لأجل الاستفادة من المشروع.</li>
            <li><b>المواد التعريفية:</b> مواد تعريفية موجهة للمنشآت الراغبة بالمشاركة في برنامج
             السلامة والصحة المهنية لتعريف المنشآت بالتقييم الذاتي ومعايير تقييم المنشآت.</li>
            <li><b>التكاليف التشغيلية:</b> تكاليف الاشتراك والمبالغ التي تتحصل للمنصة لقاء بعض الخدمات.</li>
          </ul>
        </span>
        <Typography variant="h6">ثانياً: تمهيد:</Typography>
        <span className={classes.addbottom}>
          <ul>
            <li>
              تحقيقاً لمساعي وزارة الموارد البشرية والتنمية الاجتماعية للتحول الرقمي وتطوير وأتمتة جميع الخدمات بما
              يحقق الجودة المستدامة وسهولة الإجراء وتسهيل وصول المستفيدين للخدمات المطلوبة، فقد أطلقت شركة تكامل
              لخدمات الأعمال -شركة حكومية مملوكة للدولة - بوابة "برنامج السلامة والصحة المهنية" لتكون الواجهة
              التنفيذية لخدماتها الموجهة لاستقطاب الكفاءات المهنية والفنية للدخول في سوق العمل في المملكة العربية
              السعودية.
            </li>
            <li>
              وسعياً لتحقيق جودة الخدمة والاستفادة المثلى من خدمات المنصة فإن هذه الوثيقة وما تتضمنه من شروط وأحكام
              ستكون هي المرجع النظامي لبيان الالتزامات والحقوق الناشئة عن الاستخدام وسيعتبر هذا التمهيد جزءً لا يتجزأ
              من بنودها ومكملاً لها.
            </li>
          </ul>
        </span>

        <Typography variant="h6">ثالثاً: خدمات منصة برنامج السلامة والصحة المهنية:</Typography>
        <span className={classes.addbottom}>
          <ul>
            <li>
              تهدف "المنصة" من خلال الموقع <a href="https://www.sosh.sa">www.sosh.sa</a> إلى تقليل مخاطر العمل والمحافظة على
              سلامة وصحة العاملين وتعزيز حماية العاملين من مخاطر الإصابات المهنية وحوادث العمل في المنشآت الصغيرة،
              بالإضافة إلى إصدار اللوائح والإجراءات الفنية المتعلقة بالسلامة.
            </li>
          </ul>
        </span>
        <Typography variant="h6">رابعاً: الاستفادة من "المنصة":</Typography>
        <span className={classes.addbottom}>
          تستلزم الخدمة تكاليف تشغيلية ويمكن للمستخدم تنفيذ خدمة واحدة أو الاشتراك في خدمة الاشتراك السنوي.
        </span>
        <Typography variant="h6">خامساً: شروط الاستخدام:</Typography>
        <span className={classes.addbottom}>
          تمثل البنود الآتية الحد الأدنى من الشروط العامة لاستخدام "المنصة" والتي فهم كلاً من المستفيد والمستخدم بأن
          إيرادها بالسياق العام لا يعني الحصر:
          <ul>
            <li>
              يقر المستخدم بتمتعه بكمال الأهلية المعتبرة شرعاً وأن التسجيل في المنصة تم بإرادة تامة لا تتعارض مع أي
              عارض من عوارض الأهلية.
            </li>
            <li>
              يلتزم المستخدم بتحديث بياناته الشخصية بشكل مستمر لضمان صحة البيانات وتفادي أي مشاكل قد تنشأ نتيجة
              تقديم بيانات غير صحيحة أو قديمة.
            </li>
            <li>
              يلتزم المستخدم بعدم مشاركة بيانات الدخول الخاصة بحسابه في المنصة مع أي طرف آخر أو السماح للغير باستخدام
              حسابه بما يتسبب في استخدام المنصة بطرق غير مصرح بها.
            </li>
            <li>
              يتحمل المستخدم المسؤولية الكاملة عن أي تصرف يصدر عنه أو عن غيره من خلال حسابه في المنصة والتي تحدث
              بشكل غير مصرح به بما في ذلك ما قد يحدث من أي ضرر للمنصة أو للمستفيد أو لأي جهة أخرى نتيجة لهذه
              التصرفات.
            </li>
            <li>
              يلتزم المستخدم بعدم استخدام حسابه لأي نشاط غير قانوني أو للتلاعب أو لتحصيل معلومات أو بيانات لأشخاص
              آخرين دون تفويض رسمي.
            </li>
            <li>
              يلتزم المستخدم بإبلاغ فريق الدعم بأي تجاوزات قد تحدث من خلال حسابه في المنصة والقيام بتعديل بيانات
              الحساب بصفة مستمرة لتفادي أي تجاوزات قد تحدث نتيجة اختراق الحساب أو كشف بيانات الدخول.
            </li>
            <li>
              في حال ظهور أي تغيير في البيانات الشخصية كرقم الهاتف الجوال أو البريد الإلكتروني أو العنوان الوطني
              أو أي بيانات أخرى يلزم المستخدم بإشعار المنصة بتحديث هذه البيانات على الفور.
            </li>
            <li>
              تعتبر جميع المعلومات والبيانات المقدمة من قبل المستخدم في المنصة سرية ويتم التعامل معها وفق سياسة
              الخصوصية المعلنة.
            </li>
            <li>
              تكون جميع البيانات والملفات المستضافة على المنصة خاضعة للرقابة والتأمين ضد الفيروسات والملفات
              الخبيثة، ومع ذلك فإن المنصة لا تتحمل مسؤولية أي أضرار أو فقدان للبيانات نتيجة لأي اختراق أو هجوم
              خارجي.
            </li>
            <li>
              لا يحق للمستخدم نقل حسابه أو خدماته لأي شخص آخر دون الحصول على موافقة رسمية من المنصة.
            </li>
          </ul>
        </span>
        <Typography variant="h6">سادساً: الخصوصية وسرية المعلومات:</Typography>
        <span className={classes.addbottom}>
          <ul>
            <li>
              تخضع مشاركة المعلومات والبيانات في المنصة وما يصدر منها لمستوى عالٍ من الأمان وحماية البيانات ومع ذلك
              فإن للمنصة الحق في تعديل أو تغيير سياسة هذه الخصوصية حسب ما تراه محققاً للمصلحة وما تتطلبه أنظمة
              الرقابة.
            </li>
            <li>
              يتحمل المستخدم المسؤولية عن إفشاء أو نشر أي مستخرج أو بيانات حصل عليها من المنصة مما يدخل في طبيعته في
              نطاق السرية.
            </li>
            <li>
              تلتزم المنصة بالسرية التامة لمعلومات التصفح والتي تنشأ من الخوادم المستضيفة حيث يسجل الخادم تلقائياً
              عنوان بروتوكول الاتصال الخاص بالعميل وتاريخ ووقت التصفح ونوع المتصفح وغيرها من معلومات التصفح التي قام
              بها العميل للوصول للصفحة دون تدخل من الموقع، ويبقى الالتزام بالمحافظة عليها قائماً باستثناء ما قد تطلبه
              الجهة الرسمية المختصة.
            </li>
            <li>
              تُعامل معلومات قاعدة بيانات المنصة والتي تشمل بيانات التسجيل الخاصة بالمستخدم بذات الإطار من السرية
              وعدم الإفشاء باستثناء ما قد تطلبه الجهة الرسمية المختصة.
            </li>
          </ul>
        </span>
        <Typography variant="h6">سابعاً: التكاليف المالية:</Typography>
        <span className={classes.addbottom}>
          <ul>
            <li>
              التسجيل متاح لكل مدير منشأة أو المفوض عنه والذي من خلاله سيتمكن المستخدم من استخدام المنصة والاستفادة من
              الخدمة المجانية أو المدفوعة.
            </li>
            <li>تتيح المنصة قنوات إلكترونية معتمدة من البنوك السعودية وذات معيار أمان عالٍ.</li>
            <li>تنحصر صلاحية الاستخدام على ذات المستخدم ولا يمكن النقل لمستخدم آخر أياً كانت صفته أو تبعيته.</li>
            <li>
              ينتج عن إلغاء التسجيل أو حذف العضوية إنهاء العلاقة وعدم أحقية المستخدم في استرداد المبلغ المدفوع عن
              الخدمة المدفوعة.
            </li>
            <li>
              لا تتحمل المنصة أو المطور أي التزام بشأن ما ينتج عن تعليق المبالغ عند التعامل مع قنوات الدفع الإلكتروني
              ويلتزم المستخدم بمراجعة البنك وبحث آلية استرداد المبلغ المعلق.
            </li>
          </ul>
        </span>
        <Typography variant="h6">ثامناً: حقوق الملكية الفكرية:</Typography>
        <span className={classes.addbottom}>
          <ul>
            <li>
              تمتلك المنصة كافة حقوق الملكية الفكرية والعلامات التجارية الخاصة بالمنصة، وتخضع جميع المواد المنشورة
              فيها لقوانين الحماية.
            </li>
            <li>
              يقع تحت المسؤولية والمسائلة القضائية أي تصرف، أو اقتباس أو استخدام لأي محتوى أو تصميم للمنصة ويشمل ذلك
              الرسوم التوضيحية أو الصور الفوتوغرافية أو المقاطع المرئية أو الصوتية أو أي رسومات بيانية أخرى أو مشاركة
              المحتوى في مواقع أخرى لأي غرض كان.
            </li>
            <li>
              يقر المستخدم بمسؤوليته الكاملة عن أي إخلال بهذه الشروط، وأي تبعات يتسبب بها ويحق للمطور أو المنصة اتخاذ
              الإجراءات النظامية التي تضمن حقوقهما والتعويض عن أي ضرر ينتج عن هذا الإخلال.
            </li>
            <li>
              تخضع هذه الشروط للتعديل والتحديث المستمر من قبل فريق الدعم وفق ما يستجد من التعليمات والأنظمة التي تنظم
              هذه الأعمال.
            </li>
          </ul>
        </span>
        <Typography variant="h6">تاسعاً: حل الخلافات:</Typography>
        <span className={classes.addbottom}>
          <ul>
            <li>
              أي استفسار أو خلاف حول تفسير هذه الشروط والأحكام يتم التواصل بشأنه مع فريق الدعم أو التواصل عبر البريد
              الإلكتروني <a href="mailto:support@sosh.sa">support@sosh.sa</a>
            </li>
            <li>
              التسجيل في المنصة يمثل قبولاً تاماً من المستخدم وإقراراً بالالتزامات التي تنشأ عن الاستخدام، ويتم حل
              الخلاف إن وجد عن طريق القضاء المختص في المملكة.
            </li>
          </ul>
        </span>
      </div>
      <div className={classes.footer}>
        <img src={LogoMono} alt="Logo-mono" className={classes.logo} />
        <Typography variant="body2" className={classes.links}>
          <Link href="/terms" className={classes.links}>
            {translate('termsAndConditions')}
          </Link>
        </Typography>
        <Typography variant="body2">
          {translate('copyright')} &copy; {new Date().getFullYear()}
        </Typography>
        <Link
          className={classes.backButton}
          href="/"
          underline="none"
        >
          <Button className={classes.button}>
            العودة إلى الصفحة الرئيسية
          </Button>
        </Link>
      </div>
    </div>
  );
};