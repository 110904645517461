import React, { useCallback, useRef, useState } from 'react';
import { Link, makeStyles, Theme, Typography, useMediaQuery, useTheme, SvgIcon } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import i18n from 'i18next';

import { ContactIcon } from 'ui/icons/icons';
import { TranslationNamespace } from 'i18n/config';
import LogoMono from 'images/logo-mono.svg';
import LogoColorEng from 'ui/icons/LogoColorEng.svg';
import LogoColor from 'images/logo-color.svg';
import MouseIcon from 'images/landing-mouse.svg';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import LandingBackground from 'images/landing-background.jpg';
import { ReactComponent as PhoneIcon } from 'ui/icons/phone-line-icon.svg';

import { HeroSection } from './HeroSection';
import { ContactUs } from './ContactUs';
import { LandingFirstSection } from './LandingFirstSection';
import { LandingSecondSection } from './LandingSecondSection';
import { LandingThirdSection } from './LandingThirdSection';
import { getDirection } from '../../utilities/useLanguage';

const useStyles = makeStyles<Theme>((theme) => ({
  mainWrapper: {
    position: 'relative',
    color: theme.palette.common.white,
    backgroundImage: `url(${LandingBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  header: {
    height: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      height: 108,
      paddingInline: 108,
    },
  },
  mouse: {
    position: 'absolute',
    width: '100%',
    flip: false,
    transform: 'translate(50%, 0)',
    bottom: 16,
    textAlign: 'center',
    animation: `$upAndDown 2000ms ${theme.transitions.easing.easeInOut} infinite`,
    [theme.breakpoints.up('md')]: {
      bottom: 120,
    },
  },
  '@keyframes upAndDown': {
    '0%': {
      transform: 'translateY(-8px)',
    },
    '50%': {
      transform: 'translateY(4px)',
    },
    '100%': {
      transform: 'translateY(-8px)',
    },
  },
  footer: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingInline: 40,
    },
  },
  links: {
    textDecoration: 'none',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  logo: {
    height: 40,
    [theme.breakpoints.up('md')]: {
      height: 48,
    },
  },
  language: {
    color: theme.palette.common.white,
    fontSize: '15px',
    [theme.breakpoints.down(1180)]: {
      fontSize: '10px',
    },
  },
  topTitle: {
    color: theme.palette.common.white,
    fontSize: '15px',
    marginRight: '65px',
    [theme.breakpoints.down(1180)]: {
      display: 'none',
    },
  },
  button: {
    position: 'fixed',
    borderRadius: 50,
    height: '50px',
    width: '50px',
    right: 30,
    bottom: 85,
  },
  svgIconRight: {
    marginTop: '5px',
    marginRight: '5px',
  },
  svgIconLeft: {
    marginTop: '5px',
    marginLeft: '5px',
  },
  titleHeader: {
    padding: `6px ${theme.spacing(8)}px`,
    width: '100%',
  },
  scroll: {
    marginRight: '27px',
    marginLeft: '33px',
  },
  svgIcon: {
    marginRight: '10px',
    marginTop: '4px',
  },
  copy: {
    marginLeft: 'auto',
  },
  phone: {
    marginRight: theme.spacing(4),
  },
  number: {
    marginLeft: theme.spacing(4),
  },
  phoneEN: {
    marginLeft: theme.spacing(4),
  },
}));

export const LandingPage: React.FC = () => {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const translate = useCreateTranslate(TranslationNamespace.landingPage);
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const { keycloak } = useKeycloak();
  const [language, setLanguage] = useState(i18n.language);
  const [open, setOpen] = React.useState(false);
  const direction = getDirection(i18n.language);

  const onClickBelow = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickBelow1 = useCallback(() => {
    if (ref2.current) {
      ref2.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref2]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nextSectionRef = useRef<any>();

  const scrollToNextSection = React.useCallback((): void => {
    nextSectionRef && nextSectionRef.current && nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [nextSectionRef]);

  const handleOnclick = () => {
    const newLang = i18n.language === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  };
  const handleOnclickScroll = () => {
    window.scrollTo({ top: 900, behavior: 'smooth' });
  };

  return (
    <div dir={direction}>
      <div className={classes.mainWrapper}>
        <div>
          <div className={classes.header}>
          {/* {i18n.language === 'ar' ? ( */}
          <img src={LogoColor} alt="Logo-color" className={classes.logo} />
          {/*  ) : (
             <img src={LogoColorEng} alt="Logo-color" className={classes.logo} />
           )} */}

            <div className={classes.titleHeader}>
              <Button className={classes.topTitle} onClick={scrollToNextSection} size="large">
                {translate('selfAssessment')}
              </Button>

              <Button className={classes.topTitle} onClick={onClickBelow1} size="large">
                {translate('programBenefits')}
              </Button>

              <Button className={classes.topTitle} onClick={onClickBelow} size="large">
                {translate('numbers')}
              </Button>
            </div>
            <Button className={classes.language} onClick={handleOnclick}>
              {language === 'ar' ? translate('english') : translate('arabic')}
            </Button>
          </div>
        </div>

        <HeroSection isUpMd={isUpMd} onClick={() => keycloak.login()} onClickBelow={onClickBelow} />

        <div className={classes.mouse}>
          <Button className={classes.scroll} onClick={handleOnclickScroll}>
            <img src={MouseIcon} alt="mouse" />
          </Button>
          <Typography>{translate('scrolldown')}</Typography>
        </div>
        <div>
          <Button
            variant="outlined"
            size="large"
            onClick={handleClickOpen}
            color="secondary"
            className={classes.button}
          >
            {i18n.language === 'ar' ? (
              <SvgIcon className={classes.svgIconRight} component={ContactIcon} />
            ) : (
              <SvgIcon className={classes.svgIconLeft} component={ContactIcon} />
            )}
          </Button>
          <ContactUs isFormOpen={open} handleClose={handleClose} />
        </div>
      </div>

      <LandingFirstSection ref={nextSectionRef} isUpMd={isUpMd} />

      <LandingSecondSection ref={ref2} isUpMd={isUpMd} />

      <LandingThirdSection onClick={() => keycloak.login()} isUpMd={isUpMd} ref={ref} />

      <div className={classes.footer}>
        <img src={LogoMono} alt="Logo-mono" className={classes.logo} />
        <Typography className={classes.links}>
          <Link href="/terms" className={classes.links}>
            {translate('termsAndConditions')}
          </Link>
        </Typography>
        {language === 'ar' ? (
          <>
            <Typography className={classes.number} style={{ direction: 'rtl' }}>
              3465 92000
            </Typography>
            <SvgIcon className={classes.phone} component={PhoneIcon} viewBox="0 0 170 150" />
          </>
        ) : (
          <>
            <SvgIcon className={classes.phoneEN} component={PhoneIcon} viewBox="0 0 170 150" />
            <Typography style={{ direction: 'rtl' }}>3465 92000</Typography>
          </>
        )}

        <Typography className={classes.copy} variant="body2">
          {translate('copyright')} &copy; {new Date().getFullYear()}{' '}
        </Typography>
      </div>
    </div>
  );
};
